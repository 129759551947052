<template>
    <div
        class="page iphone-x-adaptation switch-eat-type"
        :style="{ backgroundColor: $store.getters.getExposeMethod.kfcEatTypeBgColor }"
    >
        <img class="page-bg" :src="eatTypeBg" />
        <div class="content iphone-x-adaptation">
            <div class="eat-type-list">
                <div
                    class="item"
                    @click="clickItem(0)"
                    :class="{ 'under-maintenance': !$store.state.common.config.enableKfcPreorder }"
                >
                    <div class="title">自助点餐·到店自取</div>
                    <div class="tip">
                        {{
                            $store.getters.getExposeMethod.expandName == 'DongFu'
                                ? '随心兑'
                                : '5折起'
                        }}
                    </div>
                    <img src="@/assets/images/eat-type-item-01.png" alt="" class="img" />
                    <div class="btn">立即点餐(堂食/外带)</div>

                    <img
                        src="@/assets/icons/icon-under-maintenance.png"
                        class="icon"
                        v-if="!$store.state.common.config.enableKfcPreorder"
                    />
                </div>
                <template
                    v-if="
                        !$store.getters.getExposeMethod.hideMtDelivery &&
                        $store.state.common.config.enableKfcDelivery &&
                        !isTakeout
                    "
                >
                    <div class="item" @click="toTripartiteDelivery">
                        <div class="title">外卖优惠点餐</div>
                        <div class="tip">送到家</div>
                        <img src="@/assets/images/eat-type-item-03.png" class="img" />
                        <div class="btn">立即点餐</div>
                    </div>
                </template>
                <template v-else-if="$store.getters.getExposeMethod.hideMtDelivery">
                    <div
                        class="item"
                        :class="{ 'under-maintenance': !isTakeout }"
                        @click="clickItem(1)"
                    >
                        <img
                            src="@/assets/icons/icon-under-maintenance.png"
                            class="icon"
                            v-if="!isTakeout"
                        />
                        <div class="title">外卖优惠点餐</div>
                        <div class="tip">送到家</div>
                        <img src="@/assets/images/eat-type-item-02.png" class="img" />
                        <div class="btn">立即点餐</div>
                    </div>
                </template>
                <template v-else>
                    <div class="item">
                        <div class="title">外卖优惠点餐</div>
                        <div
                            class="takeout-item three"
                            :class="{
                                'under-maintenance': !$store.state.common.config.enableKfcDelivery,
                            }"
                            @click="toTripartiteDelivery"
                        >
                            <img
                                src="@/assets/icons/icon-under-maintenance.png"
                                class="takeout-item-icon"
                                v-if="!$store.state.common.config.enableKfcDelivery"
                            />
                            <div class="takeout-item-title">美团配送</div>
                            <div class="takeout-item-text">5折起</div>
                            <div class="takeout-item-tip">堂食菜单产品更多</div>
                            <img
                                class="takeout-item-img"
                                src="@/assets/icons/icon-takeout-three.png"
                            />
                        </div>
                        <div
                            class="takeout-item official"
                            :class="{ 'under-maintenance': !isTakeout }"
                            @click="clickItem(1)"
                        >
                            <img
                                src="@/assets/icons/icon-under-maintenance.png"
                                class="takeout-item-icon"
                                v-if="!isTakeout"
                            />
                            <div class="takeout-item-title">宅急送</div>
                            <div class="takeout-item-text">6折起/免运费</div>
                            <div class="takeout-item-tip">平均30分钟速达</div>
                            <img
                                class="takeout-item-img"
                                src="@/assets/icons/icon-takeout-official.png"
                            />
                        </div>
                    </div>
                </template>
            </div>
            <div class="customer-service-order-list">
                <div class="csrl-item order" @click="toOrder">
                    <div class="icon"></div>
                    <div class="title">我的订单</div>
                    <div class="text">查看订单进度</div>
                </div>
                <div class="csrl-item customer-service" @click="toCs">
                    <div class="icon"></div>
                    <div class="title">在线客服</div>
                    <div class="text">9:00 ~ 23:00</div>
                </div>
            </div>
            <div
                class="my-card"
                @click="toCard"
                v-if="!$store.getters.getExposeMethod.hideIndexOrderInlet"
            >
                <div class="icon"></div>
                <div class="title">兑换券兑换入口（我的卡包）</div>
            </div>
            <div class="tip">
                本产品为第三方代点餐/代配送服务！
                <br />
                即第三方代点餐，一切商业行为与肯德基无关。
            </div>
        </div>
        <!-- <div class="customer-service-btn"
             @click="toCs">客服</div> -->
    </div>
</template>
<script>
import CustomerService from '@/components/customerService/btn.vue';
import {
    PAGE_ROOT,
    PAGE_RESTAURANT_SELECT,
    PAGE_ADDRESS_LIST,
    PAGE_OLD_ORDER_LIST,
    PAGE_CUSTOMER_SERVICE,
    PAGE_KFC_RESTAURANT_CARD,
} from 'page';

export default {
    data() {
        return {
            isOpenBack: false,
        };
    },
    components: {
        CustomerService,
    },
    computed: {
        isTakeout() {
            return this.$store.state.common.config.enableKfcTakeout || false;
        },
        eatTypeBg() {
            if (!this.$store.getters.getExposeMethod.kfcEatTypeBgImg) {
                return require('@/assets/images/eat-type-bg.png');
            } else {
                return this.$store.getters.getExposeMethod.kfcEatTypeBgImg;
            }
        },
    },
    beforeRouteEnter(to, form, next) {
        next(vm => {
            if (form.path == PAGE_ROOT && to.query.type == 'toCard') {
                vm.$nextTick(() => {
                    vm.toCard();
                });
            }
            if (form.path == PAGE_ROOT && to.query.type == 'toOrderList') {
                vm.$nextTick(() => {
                    vm.toOrder();
                });
            }
        });
    },
    created() {
        console.log('switch-eat-type', this.$route.params);
        if (this.$route.query.backType == 2) {
            this.fobiddeBack();
        }
        // if (this.$route.query.type == 'toOrder') {
        //     this.toOrder();
        // } else if (this.$route.query.type == 'toCard') {
        //     this.toCard();
        // }
    },
    methods: {
        clickItem(type = 0) {
            if (type == 0 && this.$store.state.common.config.enableKfcPreorder) {
                this.$util.eventStatistics({
                    title: 'HallFood',
                });
                this.$util.pageJump(PAGE_RESTAURANT_SELECT);
            } else if (type == 0 && !this.$store.state.common.config.enableKfcPreorder) {
                this.$toast('堂食系统维护中');
            } else if (type == 1 && this.isTakeout) {
                this.$util.eventStatistics({
                    title: 'TakeoutFood',
                });
                this.$util.pageJump(PAGE_ADDRESS_LIST, {
                    type: 1,
                });
            } else if (type == 1 && !this.isTakeout) {
                this.$toast('外卖系统维护中');
            }
        },
        toTripartiteDelivery() {
            if (this.$store.state.common.config.enableKfcDelivery) {
                this.$util.eventStatistics({
                    title: 'TakeoutFood',
                });
                this.$util.pageJump(PAGE_ADDRESS_LIST, {
                    type: 3,
                });
            } else {
                this.$toast('外卖系统维护中');
            }
        },
        //防止页面后退
        fobiddeBack() {
            if (!this.isOpenBack) {
                console.log('%c防止页面后退', 'color:#da2f33');
                this.isOpenBack = true;
                history.pushState(null, null, document.URL);
                window.addEventListener('popstate', this.backCommon);
            }
        },
        //启用浏览器返回
        enableBack() {
            if (this.isOpenBack) {
                console.log('%c启用浏览器返回', 'color:#da2f33');
                this.isOpenBack = false;
                history.go(-1);
                window.removeEventListener('popstate', this.backCommon);
            }
        },
        backCommon() {
            history.pushState(null, null, document.URL);
        },
        toOrder() {
            this.$util.pageJump(PAGE_OLD_ORDER_LIST);
        },
        toCs() {
            this.$util.pageJump(PAGE_CUSTOMER_SERVICE);
        },
        toCard() {
            this.$util.pageJump(PAGE_KFC_RESTAURANT_CARD);
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.main {
    .switch-eat-type {
        min-height: calc(100vh - @tabbarHeight);
    }
}
.switch-eat-type {
    min-height: 100vh;
    font-size: 0;
}
.page-bg {
    width: 100%;
    margin-bottom: .pxToRem(24) [];
}
.content {
    position: absolute;
    bottom: 0;
    width: 100%;
    .eat-type-list {
        padding-left: .pxToRem(30) [];
        padding-right: .pxToRem(30) [];
        .jc-sb;

        > .item {
            width: .pxToRem(330) [];
            height: .pxToRem(450) [];
            background: white;
            box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
            border-radius: .pxToRem(40) [];
            text-align: center;
            > .icon {
                position: absolute;
                top: .pxToRem(100) [];
                left: .pxToRem(40) [];
                width: .pxToRem(240) [];
                height: .pxToRem(240) [];
                z-index: 100;
            }
            > .title {
                margin-top: .pxToRem(32) [];
                width: 100%;
                text-align: center;
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(32) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9) !important;
            }
            .tip {
                margin-top: .pxToRem(8) [];
                margin-bottom: 0;
                width: 100%;
                text-align: center;
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                font-size: .pxToRem(60) [];
                font-weight: 600;
                color: @theme_color_first;
            }
            .img {
                margin-top: .pxToRem(34) [];
                width: .pxToRem(240) [];
                height: .pxToRem(140) [];
            }
            .btn {
                margin: 0 auto;
                margin-top: .pxToRem(40) [];
                margin-bottom: .pxToRem(36) [];
                width: .pxToRem(280) [];
                text-align: center;
                height: .pxToRem(60) [];
                line-height: .pxToRem(60) [];
                background: @theme_color_first;
                border-radius: .pxToRem(30) [];
                font-size: .pxToRem(24) [];
                color: white;
            }
            &.under-maintenance {
                .title,
                .tip {
                    color: rgba(0, 0, 0, 0.3);
                }
                .img {
                    filter: grayscale(50%);
                }
                .btn {
                    background: rgba(0, 0, 0, 0.2);
                }
                // -webkit-filter: grayscale(100%);
                // -moz-filter: grayscale(100%);
                // -ms-filter: grayscale(100%);
                // -o-filter: grayscale(100%);
                // filter: grayscale(20%);
                // opacity: 0.3;
            }
        }
        .takeout-item {
            width: .pxToRem(298) [];
            height: .pxToRem(165) [];
            border-radius: .pxToRem(16) [];
            box-sizing: border-box;
            margin: 0 auto;
            margin-top: .pxToRem(16) [];
            .takeout-item-title {
                position: absolute;
                top: .pxToRem(20) [];
                left: .pxToRem(20) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(30) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
            }
            .takeout-item-text {
                position: absolute;
                top: .pxToRem(76) [];
                left: .pxToRem(20) [];
                line-height: .pxToRem(40) [];
                height: .pxToRem(40) [];
                font-size: .pxToRem(30) [];
                font-weight: 600;
                color: #d90209;
                color: @theme_color_first;
            }
            .takeout-item-tip {
                position: absolute;
                top: .pxToRem(120) [];
                left: .pxToRem(20) [];
                line-height: .pxToRem(28) [];
                height: .pxToRem(28) [];
                font-size: .pxToRem(20) [];
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
            }
            .takeout-item-img {
                position: absolute;
                bottom: .pxToRem(18) [];
                right: .pxToRem(16) [];
                height: .pxToRem(90) [];
            }
            .takeout-item-icon {
                position: absolute;
                top: 0;
                left: .pxToRem(20) [];
                width: .pxToRem(160) [];
                height: .pxToRem(160) [];
            }
            &.three {
                background: #ffeedd;
                border: .pxToRem(1) [] solid #ffdbb6;
            }
            &.official {
                background: #fdf0f0;
                border: .pxToRem(1) [] solid #feb8b8;
            }
            & + .takeout-item {
                margin-top: .pxToRem(16) [];
            }
            &.no-online {
                // -webkit-filter: grayscale(100%);
                // -moz-filter: grayscale(100%);
                // -ms-filter: grayscale(100%);
                // -o-filter: grayscale(100%);
                // filter: grayscale(100%);
                // opacity: 0.3;
            }
            &.under-maintenance {
                background: rgba(0, 0, 0, 0.1);
                border-color: rgba(0, 0, 0, 0.1);
                .takeout-item-title,
                .takeout-item-text {
                    color: rgba(0, 0, 0, 0.3);
                }
                .takeout-item-img {
                    filter: grayscale(50%);
                }
            }
        }

        .icon-coming-soon {
            position: absolute;
            top: .pxToRem(103) [];
            left: .pxToRem(106) [];
            width: .pxToRem(136) [];
            height: .pxToRem(136) [];
        }
    }
    .customer-service-order-list {
        display: flex;
        margin: 0 auto;
        margin-top: .pxToRem(24) [];
        width: .pxToRem(690) [];
        height: .pxToRem(128) [];
        background: white;
        box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
        border-radius: .pxToRem(24) [];
        .csrl-item {
            flex: 1;
            border-right: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
            .icon {
                position: absolute;
                top: .pxToRem(28) [];
                left: .pxToRem(66) [];
                width: .pxToRem(44) [];
                height: .pxToRem(44) [];
            }
            .title {
                position: absolute;
                top: .pxToRem(32) [];
                left: .pxToRem(130) [];
                height: .pxToRem(40) [];
                line-height: .pxToRem(40) [];
                font-size: .pxToRem(28) [];
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);
            }
            .text {
                position: absolute;
                bottom: .pxToRem(24) [];
                left: .pxToRem(130) [];
                height: .pxToRem(28) [];
                line-height: .pxToRem(28) [];
                font-size: .pxToRem(20) [];
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
            }
            &.order {
                .icon {
                    background: url('~@/assets/icons/icon-order-yellow.png');
                    background-size: cover;
                }
            }
            &.customer-service {
                .icon {
                    background: url('~@/assets/icons/icon-customer-service-yellow.png');
                    background-size: cover;
                }
            }
        }
    }
    .my-card {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: .pxToRem(24) [];
        width: .pxToRem(690) [];
        height: .pxToRem(88) [];
        background: white;
        box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0 rgba(0, 0, 0, 0.1);
        border-radius: .pxToRem(24) [];
        align-items: center;
        .icon {
            width: .pxToRem(44) [];
            height: .pxToRem(44) [];
            background: url('~@/assets/icons/icon-bag-yellow.png');
            background-size: cover;
        }
        .title {
            height: .pxToRem(44) [];
            line-height: .pxToRem(44) [];
            font-size: .pxToRem(28) [];
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            margin-left: .pxToRem(18) [];
        }
    }
    .tip {
        text-align: center;
        line-height: .pxToRem(36) [];
        font-size: .pxToRem(24) [];
        margin-bottom: .pxToRem(36) [];
        color: rgba(0, 0, 0, 0.3);
        margin-top: .pxToRem(36) [];
    }
}
.customer-service-btn {
    position: absolute;
    top: .pxToRem(30) [];
    right: .pxToRem(0) [];
    width: .pxToRem(140) [];
    height: .pxToRem(60) [];
    line-height: .pxToRem(60) [];
    font-size: .pxToRem(20) [];
    text-indent: .pxToRem(70) [];
    color: #e02020;
    background: #ffffff;
    box-shadow: .pxToRem(3) [] .pxToRem(8) [] .pxToRem(15) [] 0px rgba(0, 0, 0, 0.15);
    border-radius: .pxToRem(100) [] 0px 0px .pxToRem(100) [];
    &:after {
        content: ' ';
        .base-center-y;
        left: .pxToRem(20) [];
        background: url('~@/assets/icons/icon-customer-service.png');
        background-repeat: no-repeat;
        background-size: cover;
        .sq(40px);
    }
}
</style>
