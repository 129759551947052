var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page iphone-x-adaptation switch-eat-type",style:({ backgroundColor: _vm.$store.getters.getExposeMethod.kfcEatTypeBgColor })},[_c('img',{staticClass:"page-bg",attrs:{"src":_vm.eatTypeBg}}),_c('div',{staticClass:"content iphone-x-adaptation"},[_c('div',{staticClass:"eat-type-list"},[_c('div',{staticClass:"item",class:{ 'under-maintenance': !_vm.$store.state.common.config.enableKfcPreorder },on:{"click":function($event){return _vm.clickItem(0)}}},[_c('div',{staticClass:"title"},[_vm._v("自助点餐·到店自取")]),_c('div',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$store.getters.getExposeMethod.expandName == 'DongFu' ? '随心兑' : '5折起')+" ")]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/eat-type-item-01.png"),"alt":""}}),_c('div',{staticClass:"btn"},[_vm._v("立即点餐(堂食/外带)")]),(!_vm.$store.state.common.config.enableKfcPreorder)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/icon-under-maintenance.png")}}):_vm._e()]),(
                    !_vm.$store.getters.getExposeMethod.hideMtDelivery &&
                    _vm.$store.state.common.config.enableKfcDelivery &&
                    !_vm.isTakeout
                )?[_c('div',{staticClass:"item",on:{"click":_vm.toTripartiteDelivery}},[_c('div',{staticClass:"title"},[_vm._v("外卖优惠点餐")]),_c('div',{staticClass:"tip"},[_vm._v("送到家")]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/eat-type-item-03.png")}}),_c('div',{staticClass:"btn"},[_vm._v("立即点餐")])])]:(_vm.$store.getters.getExposeMethod.hideMtDelivery)?[_c('div',{staticClass:"item",class:{ 'under-maintenance': !_vm.isTakeout },on:{"click":function($event){return _vm.clickItem(1)}}},[(!_vm.isTakeout)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/icon-under-maintenance.png")}}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("外卖优惠点餐")]),_c('div',{staticClass:"tip"},[_vm._v("送到家")]),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/eat-type-item-02.png")}}),_c('div',{staticClass:"btn"},[_vm._v("立即点餐")])])]:[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("外卖优惠点餐")]),_c('div',{staticClass:"takeout-item three",class:{
                            'under-maintenance': !_vm.$store.state.common.config.enableKfcDelivery,
                        },on:{"click":_vm.toTripartiteDelivery}},[(!_vm.$store.state.common.config.enableKfcDelivery)?_c('img',{staticClass:"takeout-item-icon",attrs:{"src":require("@/assets/icons/icon-under-maintenance.png")}}):_vm._e(),_c('div',{staticClass:"takeout-item-title"},[_vm._v("美团配送")]),_c('div',{staticClass:"takeout-item-text"},[_vm._v("5折起")]),_c('div',{staticClass:"takeout-item-tip"},[_vm._v("堂食菜单产品更多")]),_c('img',{staticClass:"takeout-item-img",attrs:{"src":require("@/assets/icons/icon-takeout-three.png")}})]),_c('div',{staticClass:"takeout-item official",class:{ 'under-maintenance': !_vm.isTakeout },on:{"click":function($event){return _vm.clickItem(1)}}},[(!_vm.isTakeout)?_c('img',{staticClass:"takeout-item-icon",attrs:{"src":require("@/assets/icons/icon-under-maintenance.png")}}):_vm._e(),_c('div',{staticClass:"takeout-item-title"},[_vm._v("宅急送")]),_c('div',{staticClass:"takeout-item-text"},[_vm._v("6折起/免运费")]),_c('div',{staticClass:"takeout-item-tip"},[_vm._v("平均30分钟速达")]),_c('img',{staticClass:"takeout-item-img",attrs:{"src":require("@/assets/icons/icon-takeout-official.png")}})])])]],2),_c('div',{staticClass:"customer-service-order-list"},[_c('div',{staticClass:"csrl-item order",on:{"click":_vm.toOrder}},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"title"},[_vm._v("我的订单")]),_c('div',{staticClass:"text"},[_vm._v("查看订单进度")])]),_c('div',{staticClass:"csrl-item customer-service",on:{"click":_vm.toCs}},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"title"},[_vm._v("在线客服")]),_c('div',{staticClass:"text"},[_vm._v("9:00 ~ 23:00")])])]),(!_vm.$store.getters.getExposeMethod.hideIndexOrderInlet)?_c('div',{staticClass:"my-card",on:{"click":_vm.toCard}},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"title"},[_vm._v("兑换券兑换入口（我的卡包）")])]):_vm._e(),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_vm._v(" 本产品为第三方代点餐/代配送服务！ "),_c('br'),_vm._v(" 即第三方代点餐，一切商业行为与肯德基无关。 ")])
}]

export { render, staticRenderFns }